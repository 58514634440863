import React from 'react';

import { MemberChallenge } from '@wix/ambassador-challenge-service-web/types';
import { st, classes } from './ChallengeInfo.st.css';
import { serverTimelineTypeToClientTimelineString } from '@wix/challenges-web-library/dist/src/formatters/serverClient/timelineType';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { serverToClientPricing } from '@wix/challenges-web-library/dist/src/formatters/serverClient/price';
import { PaidPlansContext } from '../../../../contexts/PaidPlans/paidPlansContext';

export interface IChallengeInfo {
  challenge: MemberChallenge;
}

export const ChallengeInfo: React.FC<IChallengeInfo> = (props) => {
  const { language, isMobile } = useEnvironment();
  const { t } = useTranslation();
  const ppCtx = React.useContext(PaidPlansContext);

  const pricingString = serverToClientPricing(
    t,
    props.challenge.challenge,
    ppCtx.userPaidPlans,
    'challenge-card.pricing.free',
    'challenge-card.pricing.paid.separator',
    'pricing.payment-option.subscription_icu',
    'challenge.page.pricing-options.paid-general',
  );

  const durationString = serverTimelineTypeToClientTimelineString(
    props.challenge.challenge.settings.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',
    `challenge-card.duration-string.flexible.days_icu`,
    `challenge-card.duration-string.flexible.weeks_icu`,
    'challenge-card.duration-string.no-limit',
  );

  return (
    <dl
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      <div className={classes.listRow}>
        <dt className={classes.term}>
          {t('members-area.challenge-info.price')}
        </dt>
        <dd className={classes.desc}>&nbsp;{pricingString}</dd>
      </div>
      <div className={classes.listRow}>
        <dt className={classes.term}>
          {t('members-area.challenge-info.duration')}
        </dt>
        <dd className={classes.desc}>&nbsp;{durationString}</dd>
      </div>
    </dl>
  );
};
