import React from 'react';

import {
  MemberChallenge,
  ParticipantState,
} from '@wix/ambassador-challenge-service-web/types';

import { classes } from './ChallengeItem.st.css';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { UserContext } from '../../../../contexts/User/UserContext';
import { useLeaveChallengeModal } from '../../../../contexts/LeaveChallengeModal/LeaveChallengeModalContext';

export interface ILeaveChallengeButton {
  challenge: MemberChallenge;
  onChallengeRemove(challengeId: string): void;
}

export const LeaveChallengeButton: React.FC<ILeaveChallengeButton> = (
  props,
) => {
  const { t } = useTranslation();
  const userCtx = React.useContext(UserContext);
  const { showModal } = useLeaveChallengeModal();

  const isInvited =
    props.challenge.summary?.participation?.state === ParticipantState.INVITED;
  const isRequested =
    props.challenge.summary?.participation?.state ===
    ParticipantState.JOIN_REQUESTED;
  const isJoined = userTypeHandlers.isJoinedAlready(
    props.challenge.summary?.participation?.state,
  );

  if (
    isInvited ||
    userTypeHandlers.isInPaymentState(
      props.challenge.summary?.participation?.state,
    )
  ) {
    return null;
  }

  return (
    <>
      <button
        className={`${classes.link} ${classes.leaveChallengeButton}`}
        onClick={async () => {
          if (isJoined) {
            showModal(
              {
                challengeId: props.challenge.challenge.id,
                participantId:
                  props.challenge?.summary?.participation?.participantId,
              },
              () => {},
              () => {
                props.onChallengeRemove(props.challenge.challenge.id);
              },
            );
          } else {
            props.onChallengeRemove(props.challenge.challenge.id);
            await userCtx.cancelJoinRequest(props.challenge.challenge.id);
          }
        }}
      >
        {isJoined && t('members-area.cta.leave-challenge')}
        {isRequested && t('members-area.cta.cancel-request')}
      </button>
    </>
  );
};
