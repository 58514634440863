import React from 'react';

import {
  MemberChallenge,
  ParticipantState,
} from '@wix/ambassador-challenge-service-web/types';
import { st, classes } from './ChallengeItem.st.css';
import { ChallengeImage } from './ChallengeImage';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { ChallengeBadge } from '../../../ChallengesList/Badge';
import { BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { getBadgeProps } from './getBadgeProps';
import { ChallengeInfo } from './ChallengeInfo';
import { Chevron } from '../../../../components-shared/Accordion/Chevron';
import { PaidPlansContext } from '../../../../contexts/PaidPlans/paidPlansContext';
import { isChallengePaidWithoutParticipation } from './isChallengePaidWithoutparticipation';
import { LeaveChallengeButton } from './LeaveChallengeButton';
import { GoToChallenge } from './GoToChallenge';

export interface IChallengeItem {
  item: MemberChallenge;
  onChallengeRemove(challengeId: string): void;
  className?: string;
}

export const ChallengeItem: React.FC<IChallengeItem> = (props) => {
  const [expandedInfo, setExpandedInfo] = React.useState<boolean>(false);
  const ppCtx = React.useContext(PaidPlansContext);
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { item } = props;
  const isInvited =
    item.summary?.participation?.state === ParticipantState.INVITED;
  const isRequested =
    item.summary?.participation?.state === ParticipantState.JOIN_REQUESTED;

  return (
    <section
      className={st(
        classes.root,
        {
          mobile: isMobile,
        },
        props.className,
      )}
    >
      <ChallengeImage challenge={item.challenge} />

      <div className={classes.description}>
        <div className={classes.metadata}>
          <h3 className={classes.challengeName}>
            {item.challenge.settings.description.title}
          </h3>

          {isMobile && (
            <ChallengeBadge
              className={classes.badge}
              summary={item.summary}
              badgeSkin={BADGE_PRIORITY.default}
              getBadgeStrategy={getBadgeProps}
              badgeText={
                isChallengePaidWithoutParticipation(
                  item,
                  ppCtx.userPaidPlans,
                ) &&
                isInvited &&
                t('members-area.challenge-badge.payment-required')
              }
            />
          )}

          {isChallengePaidWithoutParticipation(item, ppCtx.userPaidPlans) &&
            isInvited && (
              <p className={classes.joinInfo}>
                {t('members-area.challenge-join-description.invited')}
              </p>
            )}

          {isChallengePaidWithoutParticipation(item, ppCtx.userPaidPlans) &&
            !isInvited &&
            !isRequested && (
              <p className={classes.joinInfo}>
                {t('members-area.challenge-join-description.payment-started')}
              </p>
            )}

          {expandedInfo && (
            <>
              <ChallengeInfo challenge={item} />

              <LeaveChallengeButton
                challenge={item}
                onChallengeRemove={props.onChallengeRemove}
              />
            </>
          )}

          {isMobile && <GoToChallenge challenge={item} />}
        </div>

        {!isMobile && (
          <>
            <ChallengeBadge
              className={classes.badge}
              summary={item.summary}
              badgeSkin={BADGE_PRIORITY.default}
              getBadgeStrategy={getBadgeProps}
              badgeText={
                isChallengePaidWithoutParticipation(
                  item,
                  ppCtx.userPaidPlans,
                ) &&
                isInvited &&
                t('members-area.challenge-badge.payment-required')
              }
            />
            <GoToChallenge challenge={item} />
          </>
        )}
        <button
          className={`${classes.chevron} ${
            expandedInfo ? classes.chevronExpanded : null
          }`}
          onClick={() => {
            setExpandedInfo(!expandedInfo);
          }}
        >
          <Chevron />
        </button>
      </div>
    </section>
  );
};
