import { MemberChallenge } from '@wix/ambassador-challenge-service-web/types';
import { IUserPaidPlan } from '@wix/challenges-web-library';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';
import { getPricingViewOptions } from '../../../../services/Pricing/getPricingViewOptions';

export const isChallengePaidWithoutParticipation = (
  challenge: MemberChallenge,
  paidPlans: IUserPaidPlan[],
): boolean => {
  if (
    userTypeHandlers.isJoinedAlready(challenge.summary?.participation?.state)
  ) {
    return false;
  }

  const { isHasPaidPlans, isHasSinglePayment } = getPricingViewOptions(
    challenge.challenge,
    paidPlans,
  );

  return !!isHasPaidPlans || !!isHasSinglePayment;
};
