import { V1Challenge } from '@wix/ambassador-challenge-service-web/types';
import {
  getChallengePaidPlans,
  getChallengeVisiblePaidPlans,
  isChallengeFree,
  IUserPaidPlan,
} from '@wix/challenges-web-library/dist/src';
import { MemberOrdersByPlan } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { getChallengeEligiblePaidPlans } from '../../components/ChallengesPage/Widget/components/Pricing/helpers/getChallengeEligiblePaidPlans';

export function getPricingViewOptions(
  challenge: V1Challenge,
  userPaidPlans: IUserPaidPlan[],
  eligiblePlans: MemberOrdersByPlan[] = [],
  visiblePlansOnly: boolean = false,
  includeEligiblePlans: boolean = true,
) {
  const challengePricing = challenge.settings.pricing;
  const challengeEligiblePaidPlans = getChallengeEligiblePaidPlans(
    eligiblePlans || [],
    userPaidPlans || [],
    challenge?.id,
  );
  const challengePaidPlans = (
    (visiblePlansOnly
      ? getChallengeVisiblePaidPlans(challenge.id, userPaidPlans)
      : getChallengePaidPlans(challenge.id, userPaidPlans)) || []
  ).filter((plan) => {
    return (
      includeEligiblePlans ||
      !challengeEligiblePaidPlans.find((elPlan) => elPlan.id === plan.id)
    );
  });
  const isFree = isChallengeFree(challengePricing, challengePaidPlans.length);
  const isHasSinglePayment = !!challengePricing.singlePayment;
  const isHasPaidPlans = challengePaidPlans.length;

  return {
    challengePaidPlans,
    isFree,
    isHasSinglePayment,
    isHasPaidPlans,
  };
}
