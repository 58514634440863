import React from 'react';

import { imageDataToMediaUrl } from '@wix/challenges-web-library/dist/src/helpers/image';
import { V1Challenge } from '@wix/ambassador-challenge-service-web/types';

import { classes, st } from './ChallengeImage.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const ChallengeImage: React.FC<{
  challenge: V1Challenge;
}> = (props) => {
  const { isMobile } = useEnvironment();
  const media = props.challenge?.settings?.description?.media;

  return (
    <figure
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      {media?.image && (
        <img
          src={imageDataToMediaUrl({
            ...(media.image as any),
            width: 300,
            height: 200,
          })}
          className={classes.image}
          alt={props.challenge.settings.description.title}
        />
      )}
    </figure>
  );
};
