import { ParticipantState } from '@wix/ambassador-challenge-service-web/types';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';

export const getBadgeProps = (
  participantState: ParticipantState,
): { tKey: string } => {
  if (userTypeHandlers.isJoinedAlready(participantState)) {
    return null;
  }

  if (participantState === ParticipantState.JOIN_REQUESTED) {
    return {
      tKey: 'challenge.participant.join-requested',
    };
  }

  if (userTypeHandlers.isInPaymentState(participantState)) {
    return {
      tKey: 'members-area.challenge-badge.payment-required',
    };
  }

  if (ParticipantState.JOIN_REJECTED === participantState) {
    return {
      tKey: 'challenge.participant.join-rejected',
    };
  }

  if (ParticipantState.INVITED === participantState) {
    return {
      tKey: 'members-area.challenge-badge.invited',
    };
  }

  return null;
};
